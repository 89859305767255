import { Renderer2, Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/shared/services/user.service';
import { MustMatch } from './must-match.validator';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
  animations: [
    trigger('toggleDiv', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.3s ease', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('0.3s ease', style({ opacity: 0 })),
      ]),
    ]),
  ]
})
export class SignupComponent implements OnInit {
  private _step1!: ElementRef;
  private _step2!: ElementRef;
  private _step3!: ElementRef;

  @ViewChild('step1') set step1(content: ElementRef) {
    this._step1 = content;
  }
  @ViewChild('step2') set step2(content: ElementRef) {
    this._step2 = content;
  }
  @ViewChild('step3') set step3(content: ElementRef) {
    this._step3 = content;
  }

  private _agreeTos!: ElementRef;
  private _agreePriv!: ElementRef;
  private _agreeMem!: ElementRef;
  private _agreeAll!: ElementRef;

  @ViewChild('agreeTos') set agreeTos(content: ElementRef) {
    this._agreeTos = content;
  }
  @ViewChild('agreePriv') set agreePriv(content: ElementRef) {
    this._agreePriv = content;
  }
  @ViewChild('agreeMem') set agreeMem(content: ElementRef) {
    this._agreeMem = content;
  }
  @ViewChild('agreeAll') set agreeAll(content: ElementRef) {
    this._agreeAll = content;
  }

  // form controls
  registerForm: FormGroup;
  formSubmitted = false;
  errorExists = false;
  checkbox = false;

  youtubeSubImg = null;

  myScript;

  // register steps (1-3); conditionally render form elements with *ngIf according to step
  registerStep: number = 1;

  // booleans for TOS dropdown rendering
  showTos: boolean = false;
  showPrivTerms: boolean = false;
  showMemTerms: boolean = false;

  // booleans for TOS agreement status
  tosAgree: boolean = false;
  privAgree: boolean = false;
  memAgree: boolean = false;
  allAgree: boolean = false;
  agreeSubmitted: boolean = false;
  canFinishRegister: boolean = false;

  // username duplicate check
  dupUsername: string = ''; // temporarily store violating (duplicate) username to compare with input changes and withdraw error message
  checkUsernameText: string = "아이디 중복 확인";
  usernameExists: boolean = false;
  checkUsernameDup;

  // email duplicate check
  dupEmail: string = ''; // temporarily store violating (duplicate) email to compare with input changes and withdraw error message
  checkEmailText: string = '이메일 중복 확인';
  emailExists: boolean = false;

  // password visibility controls
  passwordVisible: boolean = false; // password visibility toggle
  visibilityTimeout: any; // password visibility timeout

  constructor( 
    private formBuilder: FormBuilder,
    public router: Router,
    private userService: UserService,
    // private member: Member
    private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document
  ) { 
  }

  ngOnInit() {

    this.myScript = this._renderer2.createElement('script');
    this.myScript.type = `text/javascript`;
    this.myScript.text = `
      gtag('event', 'conversion', {'send_to': 'AW-741332516/CviBCP_h2okCEKSsv-EC'})
    `;

    this.errorExists = false;
    this.registerForm = this.formBuilder.group({
      username: ['', 
        [
          Validators.required, 
          Validators.minLength(6),
          Validators.maxLength(20),
          Validators.pattern(/^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$/) // at least one letter and one number
        ]
      ],
      usernameCheckedUnique: [false, Validators.requiredTrue],
      emailCheckedUnique: [false, Validators.requiredTrue],
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(50),
        Validators.pattern(/(?!.*pass|.*word|.*1234|.*qwer|.*asdf|.*abc|.*aaaa|.*000|.*111|.*222|.*333|.*444|.*555|.*666|.*777|.*888|.*999|.*123|.*012)^(?=(.*[0-9]){1,})(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[`~!@#\$%\^&*\-_=\+\|\\<>?]){1,})([a-zA-Z0-9\`~!@#\$%\^&\*\-_=+|\\<>/?]+)$/)
      ]],
      cpassword: ['', [Validators.required, Validators.maxLength(50)]],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(50)]], 
      fullname: ['', [Validators.required, Validators.maxLength(50)]],
      cnum1: ['', [Validators.min(10), Validators.max(999), Validators.required]],
      cnum2: ['', [Validators.min(100), Validators.max(9999), Validators.required]],
      cnum3: ['', [Validators.min(1000), Validators.max(9999), Validators.required]],
      acceptTerms: [false, Validators.requiredTrue]
    }, { validator: MustMatch('password', 'cpassword') });

    // reset usernameCheckedUnique when username value changes after entering duplicate
    // the trim() method removes whitespace for input sanitization.
    this.registerForm.get('username').valueChanges.subscribe((value) => {
      this.rf['usernameCheckedUnique'].setValue(false);
      this.registerForm.get('username').setValue(value.trim(), { emitEvent: false });
    })

    this.registerForm.get('fullname').valueChanges.subscribe((value) => {
      this.registerForm.get('fullname').setValue(value.trim(), { emitEvent: false });
    })

    // reset emailCheckedUnique when username value changes after entering duplicate
    this.registerForm.get('email').valueChanges.subscribe((value) => {
      this.rf['emailCheckedUnique'].setValue(false);
      this.registerForm.get('email').setValue(value.trim(), { emitEvent: false });
    })

    // track password validation
    this.registerForm.get('password').valueChanges.subscribe(() => {
      this.registerForm.get('cpassword').updateValueAndValidity(); // trigger re-validation
    })
  }

  get rf() { return this.registerForm.controls; }

  changeCheckbox(){
    this.checkbox = !this.checkbox;
  }

  checkUsernameUnique(): void {
    const usernameValue = this.rf['username'].value;
  
    if (usernameValue.length < 6) {
      // alert("아이디가 너무 짧습니다.");
    } else {
      this.checkUsernameText = "확인중...";
      this.userService.vaildUsername(usernameValue).subscribe(
        (data) => {
          this.rf['usernameCheckedUnique'].setValue(true);

          if (data.res_code === 1) {
            this.usernameExists = false; // reset flag
            // alert("사용 가능한 아이디 입니다.");
            this.checkUsernameDup = usernameValue;
          } else {
            this.dupUsername = usernameValue;
            this.usernameExists = true;
            // alert("이미 사용중인 아이디 입니다. 다른 아이디를 입력해 주세요.");
          }
        },
        (error) => {
          if (error.status === 400) {
            this.usernameExists = true;
            this.rf['usernameCheckedUnique'].setValue(true);
          //alert("이미 사용중인 아이디 입니다. 다른 아이디를 입력해 주세요.");
          } else {
            console.error('error checking for duplicate usernames:', error);
          }
        },
        () => {
          this.checkUsernameText = "중복확인";
        }
      );
    }
  }

  checkEmailUnique(): void {
    const emailValue = this.rf['email'].value;

    this.checkEmailText = '확인중...';
    this.userService.vaildEmail(emailValue).subscribe(
      (data) => {
        this.rf['emailCheckedUnique'].setValue(true);

        if (data.res_code === 1) {
          this.emailExists = false; // reset flag
        } else {
          this.dupEmail = emailValue;
          this.emailExists = true;
        }
        // alert('사용 가능한 이메일 입니다.')
      },
      (error) => {
        if (error.status === 400) {
          this.emailExists = true;
          this.rf['emailCheckedUnique'].setValue(true);
          // alert("이미 사용중인 이메일 입니다. 다른 이메일을 입력해 주세요.");
        } else {
          console.error('error checking for duplicate emails:', error);
        }
      }
    );
  }

  fileUpload(e){
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      this.youtubeSubImg = file;
    }
  }

  signupUser = () => {
    this.formSubmitted = true;
    if (this.hasAgreeAll() && this.registerForm.valid) {
      this.userService.signup(this.registerForm).subscribe(
        data => {
          // alert("");
          // this._renderer2.appendChild(this._document.body, this.myScript);

          this.router.navigate(['kr/login']).then(()=>{
            alert('정상적으로 가입이 완료되었습니다');
          });
  
        }, error => {
          if (error.status == 400) {
            this.errorExists = true;
          }
        }
      );
    } else {
      this.errorExists = true;
      // alert("asdf");
      // alert("fullname " + this.registerForm.get('fullname').hasError('required'))
      // alert("email " + this.registerForm.get('email').hasError('required'))
      // alert("username " + this.registerForm.get('username').hasError('required'))
      // alert("password " + this.registerForm.get('password').hasError('required'))
      // alert("cpassword " + this.registerForm.get('cpassword').hasError('required'))
      // alert("cnum1 " + this.registerForm.get('cnum1').hasError('required'))
      // alert("cnum2 " + this.registerForm.get('cnum2').hasError('required'))
      // alert("cnum3 " + this.registerForm.get('cnum3').hasError('required'))
      // alert("tnum1 " + this.registerForm.get('tnum1').hasError('required'))
      // alert("tnum2 " + this.registerForm.get('tnum2').hasError('required'))
      // alert("tnum3 " + this.registerForm.get('tnum3').hasError('required'))
      // alert("address " + this.registerForm.get('address').hasError('required'))
    }
  }

  // function to move to next input field after current field is filled
  autoMoveToNextField(event: KeyboardEvent, nextFieldId: string, maxLength: number) {
    const target = event.target as HTMLInputElement;
    if (target.value.length === maxLength) {
      const nextField = document.getElementById(nextFieldId) as HTMLInputElement;
      if (nextField) {
        nextField.focus();
      }
    }
  }

  // function to move to previous field when backspace is pressed on an empty field
  autoMoveToPreviousField(event: KeyboardEvent, prevFieldId: string) {
    const target = event.target as HTMLInputElement;
    if (event.key === 'Backspace' && target.value.length === 0) {
      const prevField = document.getElementById(prevFieldId) as HTMLInputElement;
      if (prevField) {
        prevField.focus();
      }
    }
  }

  // sanitize input to allow only numeric values
  sanitizeInput(event: any, maxLength: number) {
    const input = event.target;
    // if input contains any non-numeric characters, replace them
    let sanitizedValue = input.value.replace(/[^0-9]/g, '');

    // limit input to specified max length
    if (sanitizedValue.length > maxLength) {
      sanitizedValue = sanitizedValue.slice(0, maxLength);
    }

    input.value = sanitizedValue;

    // update form control with sanitized value
    this.registerForm.get(input.id).setValue(sanitizedValue);
  }

  gotoRegisterStep(dst: number): void {
    if (dst < 1 || dst > 3) {
      console.log('invalid register step number.');
      return;
    }

    $('html,body').stop().animate({
      scrollTop : 0
    });

    if (dst === 2) {
      this.agreeSubmitted = true;
      if (!this.hasAgreeAll()) {
        return;
      }
    }

    if (dst === 3) {
      this.formSubmitted = true;
      if (!this.registerForm.valid) {
        return;
      }

      this.onSubmit();
    }

    this.registerStep = dst;
  }

  rotateChevron(chevronId: string, isDropdownOpen: boolean): void {
    const chevron = document.getElementById(chevronId);
    if (chevron) {
      chevron.style.transform = isDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)';
      chevron.style.transition = 'transform 0.3s ease';
    }
  }

  toggleTosDropdown(): void {
    this.showTos = !this.showTos;
    this.rotateChevron('tosChevron', this.showTos);
    this.tosAgree ? this.agree([this._agreeTos]) : this.disagree([this._agreeTos]);
  }

  togglePrivTermsDropdown(): void {
    this.showPrivTerms = !this.showPrivTerms;
    this.rotateChevron('privChevron', this.showPrivTerms);
    this.privAgree ? this.agree([this._agreePriv]) : this.disagree([this._agreePriv]);
  }
  
  toggleMemTermsDropdown(): void {
    this.showMemTerms = !this.showMemTerms;
    this.rotateChevron('memChevron', this.showMemTerms);
    this.memAgree ? this.agree([this._agreeMem]) : this.disagree([this._agreeMem]);
  }

  toggleAgreeTos(): void {
    this.tosAgree = !this.tosAgree;
    this.tosAgree ? this.agree([this._agreeTos]) : this.disagree([this._agreeTos]);
    this.modifyAgreeAll();
  }

  toggleAgreePriv(): void {
    this.privAgree = !this.privAgree;
    this.privAgree ? this.agree([this._agreePriv]) : this.disagree([this._agreePriv]);
    this.modifyAgreeAll();
  }

  toggleAgreeMem(): void {
    this.memAgree = !this.memAgree;
    this.memAgree ? this.agree([this._agreeMem]) : this.disagree([this._agreeMem]);
    this.modifyAgreeAll();
  }

  toggleAgreeAll(): void {
    if (this.allAgree) {
      this.allAgree = this.tosAgree = this.privAgree = this.memAgree = false;
      this.disagree([this._agreeAll, this._agreeTos, this._agreePriv, this._agreeMem]);
    } else {
      this.agree([this._agreeAll, this._agreeTos, this._agreePriv, this._agreeMem]);
      this.allAgree = this.tosAgree = this.privAgree = this.memAgree = true;
    }
  }

  agree(els: ElementRef[]): void {
    if (els) {
      els.forEach(el => {
        if (el) {
          el.nativeElement.querySelector('img').src = "../../../../assets/icons/Icon_64px_col-36.png"
        }
      });
    }
  }

  disagree(els: ElementRef[]): void {
    if (els) {
      els.forEach(el => {
        if (el) {
          el.nativeElement.querySelector('img').src = "../../../../assets/icons/Icon_64px_col-35.png"
        }
      });
    }
  }

  modifyAgreeAll(): void {
    if (this.hasAgreeAll()) { 
      this.allAgree = true;
      this.agree([this._agreeAll]);
    } else {
      this.allAgree = false;
      this.disagree([this._agreeAll]);
    }
  }

  hasAgreeAll(): boolean {
    if (this.tosAgree && this.privAgree && this.memAgree) {
      this.rf['acceptTerms'].setValue(true);
    }
    return this.tosAgree && this.privAgree && this.memAgree;
  }

  checkCanFinishRegister(): void {
    if (this.hasAgreeAll()) {
      this.gotoRegisterStep(2);
    } else {
      return;
    }
  }

  // funciton to toggle visibility in password input field
  togglePasswordVisibility(): void {
    this.passwordVisible = !this.passwordVisible;

    // clear previous timeout
    if (this.visibilityTimeout) {
      clearTimeout(this.visibilityTimeout);
    }

    // set timeout to obfuscate password after 3 seconds
    if (this.passwordVisible) {
      this.visibilityTimeout = setTimeout(() => {
        this.passwordVisible = false;
      }, 3000); // hide password after 3 seconds
    }
  }

  gotoHome(): void {
    this.router.navigate(['/kr/home']);
  }

  gotoLogin(): void {
    this.router.navigate(['/kr/login']);
  }

  onSubmit() {
    this.formSubmitted = true;

    // stop here if form is invalid or file is not uploaded
    if (this.registerForm.invalid) {
      return;
    }

    this.signupUser();
  }
}
